import type {
  CartItem,
  Material,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'

export const useCartMethods = () => {
  async function getMaterialsCount(): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      const { data } = await useNuxtApiFetch<ApiResponse<number>>(
        '/user/cart/count'
      )
      cart.setMaterialsCount(data.value?.data)
    } else if (cart.uuid) {
      const { data } = await useApiFetch<ApiResponse<number>>(
        '/cart/getMaterialsCount',
        {
          query: {
            client_uuid: cart.uuid
          }
        }
      )
      cart.setMaterialsCount(data.value?.data)
    }
  }
  async function syncMaterials(): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      const res = await useNuxtApiFetch('/user/cart/getCart', {
        query: {
          client_uuid: cart.uuid || undefined // do not send null key
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })

      cart.uuid = null

      return res
    } else if (cart.uuid) {
      return await useApiFetch('/cart', {
        query: {
          client_uuid: cart.uuid
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function clearMaterials(): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/clearCart', {
        method: 'DELETE'
      })
    } else if (cart.uuid) {
      return await useApiFetch('/cart', {
        method: 'DELETE',
        query: {
          client_uuid: cart.uuid
        }
      })
    }
  }
  function reducePropertiesLookupValues(
    propertiesLookupValues?: PropertiesLookupValue[]
  ) {
    const props = propertiesLookupValues?.reduce((r: any, a) => {
      return [
        ...r,
        {
          property_definition_id: a.property_id || a.property_definition_id,
          value_id: a.value_id
        }
      ]
    }, [])

    return props?.length ? props : []
  }
  async function addMaterial(
    materialId: number,
    quantity?: number,
    propertiesLookupValues?: PropertiesLookupValue[],
    branchId?: number
  ): Promise<any> {
    const cart = useCartStore()
    const propertiesLookups = reducePropertiesLookupValues(
      propertiesLookupValues
    )
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/addMaterial', {
        method: 'POST',
        body: {
          material_id: materialId,
          branch_id: branchId,
          properties_lookup_values: propertiesLookupValues?.length
            ? propertiesLookupValues
            : undefined,
          propertiesLookupValues: propertiesLookups?.length
            ? propertiesLookups
            : undefined,
          count: quantity || 1
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch('/cart/new-item', {
        method: 'POST',
        body: {
          client_uuid: cart.uuid,
          material_id: materialId,
          branch_id: branchId,
          properties_lookup_values: propertiesLookupValues?.length
            ? propertiesLookupValues
            : undefined,
          propertiesLookupValues: propertiesLookups?.length
            ? propertiesLookups
            : undefined,
          count: quantity || 1
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function setTableToStore(
    storeID: number | string,
    TableID: any,
    branchId?: number
  ): Promise<any> {
    const selectedBranchId = branchId || useBranchId()
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/setTableToStore', {
        method: 'POST',
        body: {
          table_id: TableID,
          store_id: storeID,
          branch_id: selectedBranchId
        },

        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch(`/cart/assignTableToStore/${storeID}`, {
        method: 'POST',
        body: {
          client_uuid: cart.uuid,
          table_id: TableID,
          branch_id: selectedBranchId
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function removeMaterialsStore(storeID: number): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/removeMaterialsStoreCart', {
        method: 'DELETE',
        body: {
          store_id: storeID
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch(`/cart/store/${storeID}`, {
        method: 'DELETE',
        body: {
          client_uuid: cart.uuid
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function editMaterial(data: object): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/editCart', {
        method: 'POST',
        body: {
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch('/cart/editMaterialFromCart', {
        method: 'POST',
        body: {
          client_uuid: cart.uuid,
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function editAddonsInCart(data: object, itemId: number): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/editAddonsCart', {
        method: 'POST',
        body: {
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch(`/cart/${itemId}/addons`, {
        method: 'POST',
        body: {
          client_uuid: cart.uuid,
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function addMaterialWithAddons(data: object): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/addAddonsCart', {
        method: 'POST',
        body: {
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch('/cart/new-item-with-addon', {
        method: 'POST',
        body: {
          client_uuid: cart.uuid,
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function deleteMaterial(data: object): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/removeFromCart', {
        method: 'DELETE',
        body: {
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch('/cart/deleteMaterialFromCart', {
        method: 'DELETE',
        query: {
          client_uuid: cart.uuid,
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }
  async function editOffer(data: object): Promise<any> {
    const cart = useCartStore()
    if (useMenuModel().isAuthLoggedIn()) {
      return await useNuxtApiFetch('/user/cart/editCartOffer', {
        method: 'POST',
        body: {
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    } else if (cart.uuid) {
      return await useApiFetch('/cart/material-group-offer', {
        method: 'POST',
        body: {
          client_uuid: cart.uuid,
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      })
    }
  }

  function handleRequestResult(RequestResul: any) {
    const { cartModel } = useMenuModel()
    const dataLocal = RequestResul.data || {}
    dataLocal.cart_items = dataLocal?.cart_items?.map(cartModel) || []
    return dataLocal
  }

  function getCodeForApiDeliveryMethod(shippingType?: string) {
    switch (shippingType) {
      case 'store_pickup':
        return 0
      case 'delivery_by_sela':
        return 1
      case 'store_delivering_way':
        return 2
      case 'external_shipping':
        return 3
    }
    return 0
  }
  function matchItems(
    items: CartItem[],
    materialProp: Material,
    addonsAttributes?: any,
    groupOfferId?: number
  ): any {
    const material = { ...materialProp }

    return items?.reduce((r: any, a: CartItem, indexLocal: number) => {
      if (a.material.id !== material.id) {
        return r
      }

      if ((a.material_group_offer_id ?? null) !== (groupOfferId ?? null)) {
        return r
      }

      if (!((a.addons?.length || 0) === (addonsAttributes?.length || 0))) {
        return r
      }
      if (a.addons?.length && addonsAttributes?.length) {
        let matches = 0
        a.addons.forEach((extraAttributesItem) => {
          const i = addonsAttributes.findIndex(
            (etraAttributesMaterial: any) => {
              return extraAttributesItem.id === etraAttributesMaterial.id
            }
          )
          if (Number.isSafeInteger(i) && i >= 0) {
            matches++
          }
        })
        if (matches === a.addons!.length) {
          r.push({
            item: a,
            index: indexLocal
          })
          return r
        } else {
          return r
        }
      } else {
        r.push({
          item: a,
          index: indexLocal
        })
        return r
      }
    }, [])
  }
  return {
    matchItems,
    editMaterial,
    editOffer,
    deleteMaterial,
    syncMaterials,
    setTableToStore,
    editAddonsInCart,
    clearMaterials,
    getCodeForApiDeliveryMethod,
    getMaterialsCount,
    addMaterial,
    reducePropertiesLookupValues,
    addMaterialWithAddons,
    removeMaterialsStore
  }
}
