<template>
  <AppProvider>
    <NuxtPage />
  </AppProvider>
</template>

<style>
.app-enter-active,
.app-leave-active {
  transition: all 0.2s;
}
.app-enter-from,
.app-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
