import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification'
import type {
  CartItem,
  Material,
  GroupOffer,
  Store,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'

const {
  editMaterial,
  addMaterial,
  editOffer,
  reducePropertiesLookupValues,
  matchItems,
  deleteMaterial,
  setTableToStore,
  editAddonsInCart,
  syncMaterials,
  removeMaterialsStore,
  addMaterialWithAddons,
  clearMaterials
} = useCartMethods()

const createCartStore = (keyPinaStore: string) =>
  defineStore(keyPinaStore, {
    state: (): {
      items: CartItem[]
      stores_tables: {
        store_id?: string
        table_id?: string
        branch_id?: any
        table_number?: string
      }[]
      validatedItems: CartItem[]
      materialsCount: number
      hasToken?: boolean | null
      loading: boolean
      uuid: string | null
      errors: Record<string, any>
    } => ({
      items: [],
      loading: false,
      stores_tables: [],
      validatedItems: [],
      materialsCount: 0,
      uuid: null,
      hasToken: null,
      errors: {}
    }),

    getters: {
      getItem: (state) => {
        return (
          material: Material,
          addonsAttributes?: any,
          groupOfferId?: number
        ) => {
          const items = matchItems(
            state.items,
            material,
            addonsAttributes,
            groupOfferId
          )
          return items.length ? items[0]?.item : null
        }
      },
      isAuthLoggedIn: () => {
        return useMenuModel().isAuthLoggedIn()
      },
      getUuid: (state) => () => {
        return state.uuid
      },
      quantity: (state) => {
        return (
          material: Material,
          addonsAttributes?: any,
          groupOfferId?: number
        ) => {
          const items = matchItems(
            state.items,
            material,
            addonsAttributes,
            groupOfferId
          )
          return items.length ? items[0]?.item?.quantity : 0
        }
      },
      itemsWithOutOffer: (state) => () => {
        return state.items.filter(
          (item) =>
            item.material_group_offer_id === undefined ||
            item.material_group_offer_id === null
        )
      },
      count: () => (items: CartItem[]) => {
        return items.length
      },

      getTableByID: (state) => (storeID: number) => {
        return state.stores_tables.find(
          (item) => parseFloat(item.store_id || '') === storeID
        )
      },
      badgeCount(): number | string {
        // @ts-ignore
        return this.getCount() > 9 ? '+9' : this.getCount()
      },
      itemsByStore: (state) => {
        const itemsLocal = [...state.items]
        /* gruop itemsByStore WithOut Sorted by offers */
        const itemsGroupedByStore = itemsLocal?.reduce(
          (
            r: Record<
              string,
              {
                id: number
                branchId?: number
                store: Store
                cartItems: CartItem[]
                branch?: BranchStore
              }
            >,
            a: CartItem
          ) => {
            if (a.material.store) {
              r[`${a.material.store.id}-${a.branch_id}`] = {
                id: a.material.store.id,
                branchId: a.branch_id,
                branch: a.branch,
                store: a.material.store,
                cartItems: [
                  ...(r[`${a.material.store.id}-${a.branch_id}`]?.cartItems ||
                    []),
                  a
                ]
              }
            }
            return r
          },
          {}
        )

        return itemsGroupedByStore
      } /* Sorted itemsByStore  by offers */,
      itemsByStoreSortedByOffer(): any[] {
        const itemsLocal = this.itemsByStore
        const cartItemsPerStoreSorted = []
        for (const key in itemsLocal) {
          const cartItemsPerStore = { ...itemsLocal[key] } as {
            id: number
            branchId?: number
            store: Store
            branch?: BranchStore
            cartItems?: CartItem[]
            items: any
          }
          cartItemsPerStore.items = {
            withOffers: cartItemsPerStore.cartItems?.reduce(
              (r: any, a: CartItem) => {
                if (a.group_offer_uuid) {
                  if (r[a.group_offer_uuid]) {
                    r[a.group_offer_uuid].push(a)
                  } else {
                    r[a.group_offer_uuid] = []
                    r[a.group_offer_uuid].push(a)
                  }
                }
                return r
              },
              {}
            ),
            withOutOffers: cartItemsPerStore.cartItems?.filter(
              (item: CartItem) =>
                item.material_group_offer_id === undefined ||
                item.material_group_offer_id === null
            )
          }
          delete cartItemsPerStore.cartItems
          cartItemsPerStoreSorted.push(cartItemsPerStore)
        }

        return cartItemsPerStoreSorted
      },
      quantityItemsPerStore:
        (state) => (storelId: number, branchId?: number) => {
          const itemsLocal = [...state.items]
          return itemsLocal.reduce((r: number, a: CartItem) => {
            if (
              a.material?.store?.id === storelId &&
              a.branch_id === branchId
            ) {
              r = r + 1
            }
            return r
          }, 0)
        }
    },

    actions: {
      setValidationItems(items: CartItem[]) {
        this.validatedItems = items
      },
      setNewUuid() {
        this.uuid = this.uuid || uuidv4()
        return this.uuid
      },

      setMaterialsCount(count?: number) {
        this.materialsCount = count || 0
      },
      setErrors(storeId: string | undefined, items: any) {
        if (storeId) {
          this.errors[storeId] = items
        }
      },
      setItems(resultApi: any) {
        if (resultApi.cart_items) {
          const itemsFiltered = resultApi.cart_items.filter(
            (el: CartItem) => el.material?.store?.cart_visible === 1
          )
          this.setMaterialsCount(this.count(itemsFiltered))
          this.items = itemsFiltered
        } else {
          this.setMaterialsCount(0)
          this.items = []
        }

        if (resultApi.stores_tables) {
          this.stores_tables = resultApi.stores_tables
        } else {
          this.stores_tables = []
        }
      },
      async addItem(
        material: Material,
        quantity?: number,
        propertiesLookupValues?: PropertiesLookupValue[],
        branchId?: number
      ) {
        const storeId = material.store?.id
          ? material.store.id.toString()
          : undefined

        return this.handleReponseApi(
          await addMaterial(
            material.id,
            quantity,
            propertiesLookupValues,
            branchId
          ),
          storeId
        )
      },
      async removeMaterialsStoreCart(storeId: number) {
        this.setErrors(storeId.toString(), undefined)
        this.handleReponseApi(
          await removeMaterialsStore(storeId),
          storeId.toString()
        )
      },
      async addItemWithAddonAttributes(
        material: Material,
        quantity = 1,
        addonsAttributes?: { addon_id: string; quantity: number }[],
        materialGroupOffe?: GroupOffer,
        propertiesLookupValues?: PropertiesLookupValue[],
        branchId?: number
      ) {
        const propertiesLookups = reducePropertiesLookupValues(
          propertiesLookupValues
        )
        const bodyRequest = {
          material_id: material.id,
          count: quantity,
          operation: '+',
          properties_lookup_values: propertiesLookupValues?.length
            ? propertiesLookupValues
            : undefined,
          propertiesLookupValues: propertiesLookups?.length
            ? propertiesLookups
            : undefined,
          material_group_offer_id: materialGroupOffe?.id,
          branch_id: branchId,
          addons: addonsAttributes
        } as any
        const storeId = material.store?.id
          ? material.store!.id.toString()
          : undefined
        this.setErrors(storeId, undefined)
        return this.handleReponseApi(
          await addMaterialWithAddons(bodyRequest),
          storeId
        )
      },
      async addOffer(materialGroupOfferID: number, storeId?: number) {
        const storeIdTemp = storeId ? storeId.toString() : undefined
        return await this.handleReponseApi(
          await editOffer({ material_group_offer_id: materialGroupOfferID }),
          storeIdTemp
        )
      },

      async deleteOffer(cartItem: CartItem) {
        const storeId = cartItem.material.store?.id
          ? cartItem.material.store.id.toString()
          : undefined
        return await this.handleReponseApi(
          await this.deleteServerItem({
            store_id: cartItem.material.store?.id,
            material_group_offer_id: cartItem.material_group_offer_id,
            group_offer_uuid: cartItem.group_offer_uuid
          }),
          storeId
        )
      },

      async deleteOrAddOffer(material: Material, GroupOfferId: number) {
        const itemCart = this.getItem(material, undefined, GroupOfferId)
        if (itemCart) {
          await this.deleteOffer(itemCart)
        } else {
          await this.addOffer(GroupOfferId, material.store?.id)
        }
      },
      async deleteItem(cartItemId: number, query?: object) {
        return await this.deleteServerItem(
          query || { cart_item_id: cartItemId }
        )
      },
      async toggleAddwithAddonsAttribute(
        material: Material,
        quantity = 1,
        addonsAttributes?: { addon_id: string; quantity: number }[],
        materialGroupOffer?: GroupOffer,
        propertiesLookupValues?: PropertiesLookupValue[],
        branchId?: number
      ) {
        if (addonsAttributes?.length) {
          return await this.addItemWithAddonAttributes(
            material,
            quantity,
            addonsAttributes,
            materialGroupOffer,
            propertiesLookupValues,
            branchId
          )
        } else {
          return await this.addItem(
            material,
            quantity,
            propertiesLookupValues,
            branchId
          )
        }
      },

      async editItem(
        material: Material,
        cartItemId: number,
        branchId: number | undefined,
        operationS: string,
        quantity?: number
      ) {
        const storeId = material.store?.id
          ? material.store!.id.toString()
          : undefined
        this.setErrors(storeId, undefined)
        return this.handleReponseApi(
          await editMaterial({
            material_id: material.id,
            cart_item_id: cartItemId,
            branch_id: branchId,
            count: quantity || 1,
            operation: operationS
          }),
          storeId
        )
      },
      clear() {
        this.items = []
        this.materialsCount = 0
        this.errors = {}
      },
      catchError(
        errorProps: any,
        storeId?: string,
        customMessageError?: string
      ) {
        const error =
          errorProps?.data && errorProps?.data?.statusCode
            ? errorProps?.data
            : errorProps
        const toast = useToast()
        const { t } = useNuxtApp().$i18n

        if (error?.statusCode === 422) {
          if (error?.data?.errors?.bill_detail_errors) {
            this.setErrors(
              storeId?.toString(),
              error?.data?.errors.bill_detail_errors
            )
            this.setValidationItems(error?.data.errors.new_bill_details)
          } else {
            const errArray = []
            if (error?.data?.errors) {
              for (const key in error?.data.errors) {
                if (Object.hasOwnProperty.call(error?.data.errors, key)) {
                  const element = error?.data.errors[key]
                  errArray.push(...element)
                }
              }
            } else if (error?.data?.message) {
              errArray.push(error?.data.message)
            }

            toast.error(
              errArray.length
                ? errArray[0]
                : customMessageError || t('error_500'),
              {
                timeout: 3000
              }
            )
            this.setErrors(storeId?.toString(), errArray)
          }
        } else {
          toast.error(
            customMessageError || error?.data?.message || t('error_500'),
            { timeout: 3000 }
          )
        }
      },

      async editAddonAttribute(
        material: Material,
        attributeId: string,
        cartItemId: number,
        operations: string,
        quantity = 1
      ) {
        const storeId = material.store?.id
          ? material.store!.id.toString()
          : undefined
        this.setErrors(storeId, undefined)
        return this.handleReponseApi(
          await editAddonsInCart(
            {
              material_id: material.id,
              addon_id: attributeId,
              cart_id: cartItemId,
              attribute_quantity: quantity,
              operation: operations
            },
            cartItemId
          ),
          storeId
        )
      },
      async deleteServerItem(data = {} as any) {
        const { t } = useNuxtApp().$i18n
        this.setErrors(data.store_id, undefined)
        return this.handleReponseApi(
          await deleteMaterial(data),
          data.store_id?.toString(),
          t('delete_failed')
        )
      },
      async repostOldCart() {
        this.hasToken = true
        const cartItems = this.items
        let newCartResponse: any
        const storesTables = this.stores_tables
        if (storesTables.length) {
          await storesTables.forEach(async (storeTable) => {
            newCartResponse = await setTableToStore(
              storeTable.store_id!,
              storeTable.table_id,
              storeTable.branch_id
            )
          })
        }
        const cartItemsWithOutOffer = cartItems.filter(
          (el) =>
            el.material_group_offer_id === null ||
            el.material_group_offer_id === undefined
        )
        const cartItemsWithOffer = cartItems.filter(
          (el) =>
            el.group_offer_uuid !== null || el.group_offer_uuid !== undefined
        )
        const groupedItemsByOffer: Record<string, CartItem[]> =
          cartItemsWithOffer?.reduce((result, item) => {
            if (item.group_offer_uuid) {
              result[item.group_offer_uuid] =
                result[item.group_offer_uuid] || []
              result[item.group_offer_uuid].push(item)
            }
            return result
          }, {} as Record<string, CartItem[]>)
        const cartItemSkippedIdsSearch = [] as number[]
        await Object.values(groupedItemsByOffer).forEach(async (Offer) => {
          newCartResponse = await editOffer({
            material_group_offer_id: Offer[0].material_group_offer_id!
          })
          Offer.forEach(async (cartItemOffer) => {
            if (cartItemOffer.addons?.length) {
              const { data } = newCartResponse || {}
              const newCartItem = data?.value?.cart_items?.find(
                (el: CartItem) =>
                  el.material_group_offer_id ===
                    cartItemOffer.material_group_offer_id &&
                  el.material.id === cartItemOffer.material.id &&
                  !cartItemSkippedIdsSearch.includes(el.id)
              )
              if (newCartItem) {
                cartItemSkippedIdsSearch.push(newCartItem.id)
              }
              await cartItemOffer.addons.forEach(async (addon) => {
                if ((addon.pivot?.quantity || 0) > 0) {
                  if (newCartItem) {
                    newCartResponse = await editAddonsInCart(
                      {
                        material_id: cartItemOffer.material_id,
                        addon_id: addon.id,
                        cart_id: newCartItem.id,
                        attribute_quantity: addon.quantity,
                        operation: '+'
                      },
                      newCartItem.id!
                    )
                  }
                }
              })
            }
          })
        })
        await cartItemsWithOutOffer.forEach(async (itemCart) => {
          const addons = [] as { addon_id: string; quantity: number }[]
          if (itemCart.addons?.length) {
            itemCart.addons.forEach((addon) => {
              addons.push({
                addon_id: addon.id,
                quantity: addon.pivot?.quantity || 1
              })
            })
          }
          newCartResponse = await this.toggleAddwithAddonsAttribute(
            itemCart.material,
            itemCart.quantity,
            addons,
            undefined,
            itemCart.properties_lookup_values,
            itemCart.branch_id
          )
        })

        this.handleReponseApi(newCartResponse)
      },
      async serverLoad(initialLoading?: boolean) {
        if (!this.loading || initialLoading) {
          this.loading = true
          const { status } = useAuth()
          this.errors = {}
          if (this.hasToken === null || this.hasToken === undefined) {
            this.hasToken = status.value === 'authenticated'
          }
          const newCartResponse = await syncMaterials()

          const { data } = newCartResponse || {}
          if (
            this.hasToken === false &&
            status.value === 'authenticated' &&
            this.items.length &&
            !data?.value?.length
          ) {
            await this.repostOldCart()
            this.loading = false
            window.location.reload()
          } else {
            this.hasToken = status.value === 'authenticated'
            this.handleReponseApi(newCartResponse)
            this.loading = false
          }
        }
      },

      async serverClear() {
        this.errors = {}
        this.handleReponseApi(await clearMaterials())
      },

      getCount() {
        return this.materialsCount
      },
      handleReponseApi(
        reponseApi: any,
        storeId?: string,
        customMessageError?: string
      ) {
        const { data, error } = reponseApi || {}
        if (data?.value) {
          this.setItems(data?.value)
          return true
        } else if (error?.value) {
          this.catchError(error.value, storeId, customMessageError)
          return false
        }
      }
    },

    persist: [
      { paths: ['uuid'], storage: persistedState.cookies },
      {
        paths: ['items', 'stores_tables', 'hasToken', 'loading'],
        storage: persistedState.localStorage
      }
    ]
  })

export const useCartStore = () => {
  const appUrl = useAppUrl()
  const cartPiniaKey = `cart ${appUrl}`
  return createCartStore(cartPiniaKey)()
}
export const useCheckout = () => {
  const loading = ref(false)

  function checkoutHandler() {
    loading.value = true

    // Handle Buy

    loading.value = false
  }

  return {
    loading,
    checkoutHandler
  }
}
