import { FetchError } from 'ofetch'
/* import i18n from '#i18n' */
import { useCityIdStore } from '~/store/city_id_filter'

export const useApiError = (error: FetchError<any> | null) => {
  if (error) {
    showError({
      statusCode: error.response?.status || error.statusCode || 500,
      message: error.data?.message || error.message || 'Error!'
    })
  }
}

export const useToggleApiFetch = (fetchRouteInitial?: string) => {
  const { isAuthLoggedIn } = useMenuModel()
  let url = fetchRouteInitial
  if (!url) {
    url = isAuthLoggedIn() ? '/nuxt-api/user/materials' : '/material'
  }

  let handler = useApiFetch

  if (url.startsWith('/nuxt-api')) {
    url = url.replace('/nuxt-api', '')
    handler = useNuxtApiFetch
  }

  return {
    url,
    handler
  }
}
export const useDomainHeader = () => {
  const { host, layout } = useDomainHost()

  if (process.env.NODE_ENV === 'development') {
    return layout === 'platform' ? 'devstore.sila-sp.com' : useDomain()
  } else {
    return host
  }
}

function queryLocale(query: any) {
  const { cityId } = useCityIdStore()
  return {
    ...(query || {}),
    app_locale: useNuxtApp().$i18n?.locale?.value,
    city_id: cityId
  }
}

export const useApiFetch: typeof useFetch = (path, opts?) => {
  return useFetch(useBaseUrl(path as string), {
    ...(opts || {}),

    query: queryLocale(opts?.query),

    headers: {
      ...(opts?.headers || {}), // In case of authorization, content-type, etc.
      Accept: 'application/json',
      domain: useDomainHeader()
    }
  })
}

export const useNuxtApiFetch: typeof useFetch = (path, opts?) => {
  return useFetch(`/nuxt-api${path}`, {
    ...(opts || {}),

    query: queryLocale(opts?.query),

    headers: {
      ...(opts?.headers || {}), // In case of authorization, content-type, etc.
      Accept: 'application/json',
      domain: useDomainHeader()
    }
  })
}

export const useLazyApiFetch: typeof useLazyFetch = (path, opts?) => {
  return useLazyFetch(useBaseUrl(path as string), {
    ...(opts || {}),

    query: queryLocale(opts?.query),

    headers: {
      ...(opts?.headers || {}), // In case of authorization, content-type, etc.
      Accept: 'application/json',
      domain: useDomainHeader()
    }
  })
}
