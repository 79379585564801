import { default as _91id_93le4cR3R2x3Meta } from "/home/e-store/node_menu/pages/domain/about/[id].vue?macro=true";
import { default as categoriesBXNy1j0Xs6Meta } from "/home/e-store/node_menu/pages/domain/categories.vue?macro=true";
import { default as indexv1HWq3sU4dMeta } from "/home/e-store/node_menu/pages/domain/index.vue?macro=true";
import { default as _91id_93Qej5qWXfpvMeta } from "/home/e-store/node_menu/pages/domain/materials/[id].vue?macro=true";
import { default as index6QJHm4vKpSMeta } from "/home/e-store/node_menu/pages/domain/materials/index.vue?macro=true";
import { default as offers2HrpddyR2ZMeta } from "/home/e-store/node_menu/pages/domain/offers.vue?macro=true";
import { default as about_45usDKq87uBs94Meta } from "/home/e-store/node_menu/pages/platform/about-us.vue?macro=true";
import { default as email_45reset_45passwordg3hllAjW2LMeta } from "/home/e-store/node_menu/pages/platform/auth/index/email-reset-password.vue?macro=true";
import { default as forgotMdsdbfdPqVMeta } from "/home/e-store/node_menu/pages/platform/auth/index/forgot.vue?macro=true";
import { default as loginTR4JYpDLXFMeta } from "/home/e-store/node_menu/pages/platform/auth/index/login.vue?macro=true";
import { default as phone_45reset_45password7mwDkxKcYLMeta } from "/home/e-store/node_menu/pages/platform/auth/index/phone-reset-password.vue?macro=true";
import { default as registerhOVDyGgfwiMeta } from "/home/e-store/node_menu/pages/platform/auth/index/register.vue?macro=true";
import { default as indexAcqTKb9K51Meta } from "/home/e-store/node_menu/pages/platform/auth/index.vue?macro=true";
import { default as _providerpcLKZrrROBMeta } from "/home/e-store/node_menu/pages/platform/auth/social/_provider.vue?macro=true";
import { default as verify_45emailezz4R1q6sTMeta } from "/home/e-store/node_menu/pages/platform/auth/verify-email.vue?macro=true";
import { default as indexwDwOpRfyS7Meta } from "/home/e-store/node_menu/pages/platform/index.vue?macro=true";
import { default as _91id_93cUecUnoOvLMeta } from "/home/e-store/node_menu/pages/platform/materials/[id].vue?macro=true";
import { default as index35pcWJHuM6Meta } from "/home/e-store/node_menu/pages/platform/materials/index.vue?macro=true";
import { default as edito5lPr0HeiIMeta } from "/home/e-store/node_menu/pages/platform/my-account/index/edit.vue?macro=true";
import { default as indexKDQOPNGGcqMeta } from "/home/e-store/node_menu/pages/platform/my-account/index/index.vue?macro=true";
import { default as notificationsGcVJBER7TxMeta } from "/home/e-store/node_menu/pages/platform/my-account/index/notifications.vue?macro=true";
import { default as _91id_93KJ1c1kgua2Meta } from "/home/e-store/node_menu/pages/platform/my-account/index/orders/[id].vue?macro=true";
import { default as indexyrG7oiRYwGMeta } from "/home/e-store/node_menu/pages/platform/my-account/index/orders/index.vue?macro=true";
import { default as placesqsCtzDv37KMeta } from "/home/e-store/node_menu/pages/platform/my-account/index/places.vue?macro=true";
import { default as wishlistkLai0HGqeeMeta } from "/home/e-store/node_menu/pages/platform/my-account/index/wishlist.vue?macro=true";
import { default as indexx2oTV3vYZgMeta } from "/home/e-store/node_menu/pages/platform/my-account/index.vue?macro=true";
import { default as offersN0Z0vEMvauMeta } from "/home/e-store/node_menu/pages/platform/offers.vue?macro=true";
import { default as privacyTunBmyshG6Meta } from "/home/e-store/node_menu/pages/platform/privacy.vue?macro=true";
import { default as store_45typesER6Ann1IOoMeta } from "/home/e-store/node_menu/pages/platform/store-types.vue?macro=true";
import { default as _91id_93RUoi13JnnlMeta } from "/home/e-store/node_menu/pages/platform/stores/[id].vue?macro=true";
import { default as indexV2SKA9vem6Meta } from "/home/e-store/node_menu/pages/platform/stores/index.vue?macro=true";
import { default as successful_45purchase_45cartnC9GDSCanRMeta } from "/home/e-store/node_menu/pages/platform/successful-purchase-cart.vue?macro=true";
import { default as termsAJpDsJVmA5Meta } from "/home/e-store/node_menu/pages/platform/terms.vue?macro=true";
import { default as cartmVBZuYxTtYMeta } from "/home/e-store/node_menu/pages/shared/cart.vue?macro=true";
export default [
  {
    name: _91id_93le4cR3R2x3Meta?.name ?? "domain-about-id___en",
    path: _91id_93le4cR3R2x3Meta?.path ?? "/en/domain/about/:id()",
    meta: _91id_93le4cR3R2x3Meta || {},
    alias: _91id_93le4cR3R2x3Meta?.alias || [],
    redirect: _91id_93le4cR3R2x3Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/about/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93le4cR3R2x3Meta?.name ?? "domain-about-id___tr",
    path: _91id_93le4cR3R2x3Meta?.path ?? "/tr/domain/about/:id()",
    meta: _91id_93le4cR3R2x3Meta || {},
    alias: _91id_93le4cR3R2x3Meta?.alias || [],
    redirect: _91id_93le4cR3R2x3Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/about/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93le4cR3R2x3Meta?.name ?? "domain-about-id___ar",
    path: _91id_93le4cR3R2x3Meta?.path ?? "/domain/about/:id()",
    meta: _91id_93le4cR3R2x3Meta || {},
    alias: _91id_93le4cR3R2x3Meta?.alias || [],
    redirect: _91id_93le4cR3R2x3Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/about/[id].vue").then(m => m.default || m)
  },
  {
    name: categoriesBXNy1j0Xs6Meta?.name ?? "domain-categories___en",
    path: categoriesBXNy1j0Xs6Meta?.path ?? "/en/domain/categories",
    meta: categoriesBXNy1j0Xs6Meta || {},
    alias: categoriesBXNy1j0Xs6Meta?.alias || [],
    redirect: categoriesBXNy1j0Xs6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/categories.vue").then(m => m.default || m)
  },
  {
    name: categoriesBXNy1j0Xs6Meta?.name ?? "domain-categories___tr",
    path: categoriesBXNy1j0Xs6Meta?.path ?? "/tr/domain/categories",
    meta: categoriesBXNy1j0Xs6Meta || {},
    alias: categoriesBXNy1j0Xs6Meta?.alias || [],
    redirect: categoriesBXNy1j0Xs6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/categories.vue").then(m => m.default || m)
  },
  {
    name: categoriesBXNy1j0Xs6Meta?.name ?? "domain-categories___ar",
    path: categoriesBXNy1j0Xs6Meta?.path ?? "/domain/categories",
    meta: categoriesBXNy1j0Xs6Meta || {},
    alias: categoriesBXNy1j0Xs6Meta?.alias || [],
    redirect: categoriesBXNy1j0Xs6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/categories.vue").then(m => m.default || m)
  },
  {
    name: indexv1HWq3sU4dMeta?.name ?? "domain___en",
    path: indexv1HWq3sU4dMeta?.path ?? "/en/domain",
    meta: indexv1HWq3sU4dMeta || {},
    alias: indexv1HWq3sU4dMeta?.alias || [],
    redirect: indexv1HWq3sU4dMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/index.vue").then(m => m.default || m)
  },
  {
    name: indexv1HWq3sU4dMeta?.name ?? "domain___tr",
    path: indexv1HWq3sU4dMeta?.path ?? "/tr/domain",
    meta: indexv1HWq3sU4dMeta || {},
    alias: indexv1HWq3sU4dMeta?.alias || [],
    redirect: indexv1HWq3sU4dMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/index.vue").then(m => m.default || m)
  },
  {
    name: indexv1HWq3sU4dMeta?.name ?? "domain___ar",
    path: indexv1HWq3sU4dMeta?.path ?? "/domain",
    meta: indexv1HWq3sU4dMeta || {},
    alias: indexv1HWq3sU4dMeta?.alias || [],
    redirect: indexv1HWq3sU4dMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Qej5qWXfpvMeta?.name ?? "domain-materials-id___en",
    path: _91id_93Qej5qWXfpvMeta?.path ?? "/en/domain/materials/:id()",
    meta: _91id_93Qej5qWXfpvMeta || {},
    alias: _91id_93Qej5qWXfpvMeta?.alias || [],
    redirect: _91id_93Qej5qWXfpvMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Qej5qWXfpvMeta?.name ?? "domain-materials-id___tr",
    path: _91id_93Qej5qWXfpvMeta?.path ?? "/tr/domain/materials/:id()",
    meta: _91id_93Qej5qWXfpvMeta || {},
    alias: _91id_93Qej5qWXfpvMeta?.alias || [],
    redirect: _91id_93Qej5qWXfpvMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Qej5qWXfpvMeta?.name ?? "domain-materials-id___ar",
    path: _91id_93Qej5qWXfpvMeta?.path ?? "/domain/materials/:id()",
    meta: _91id_93Qej5qWXfpvMeta || {},
    alias: _91id_93Qej5qWXfpvMeta?.alias || [],
    redirect: _91id_93Qej5qWXfpvMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: index6QJHm4vKpSMeta?.name ?? "domain-materials___en",
    path: index6QJHm4vKpSMeta?.path ?? "/en/domain/materials",
    meta: index6QJHm4vKpSMeta || {},
    alias: index6QJHm4vKpSMeta?.alias || [],
    redirect: index6QJHm4vKpSMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index6QJHm4vKpSMeta?.name ?? "domain-materials___tr",
    path: index6QJHm4vKpSMeta?.path ?? "/tr/domain/materials",
    meta: index6QJHm4vKpSMeta || {},
    alias: index6QJHm4vKpSMeta?.alias || [],
    redirect: index6QJHm4vKpSMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index6QJHm4vKpSMeta?.name ?? "domain-materials___ar",
    path: index6QJHm4vKpSMeta?.path ?? "/domain/materials",
    meta: index6QJHm4vKpSMeta || {},
    alias: index6QJHm4vKpSMeta?.alias || [],
    redirect: index6QJHm4vKpSMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/materials/index.vue").then(m => m.default || m)
  },
  {
    name: offers2HrpddyR2ZMeta?.name ?? "domain-offers___en",
    path: offers2HrpddyR2ZMeta?.path ?? "/en/domain/offers",
    meta: offers2HrpddyR2ZMeta || {},
    alias: offers2HrpddyR2ZMeta?.alias || [],
    redirect: offers2HrpddyR2ZMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/offers.vue").then(m => m.default || m)
  },
  {
    name: offers2HrpddyR2ZMeta?.name ?? "domain-offers___tr",
    path: offers2HrpddyR2ZMeta?.path ?? "/tr/domain/offers",
    meta: offers2HrpddyR2ZMeta || {},
    alias: offers2HrpddyR2ZMeta?.alias || [],
    redirect: offers2HrpddyR2ZMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/offers.vue").then(m => m.default || m)
  },
  {
    name: offers2HrpddyR2ZMeta?.name ?? "domain-offers___ar",
    path: offers2HrpddyR2ZMeta?.path ?? "/domain/offers",
    meta: offers2HrpddyR2ZMeta || {},
    alias: offers2HrpddyR2ZMeta?.alias || [],
    redirect: offers2HrpddyR2ZMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/domain/offers.vue").then(m => m.default || m)
  },
  {
    name: about_45usDKq87uBs94Meta?.name ?? "platform-about-us___en",
    path: about_45usDKq87uBs94Meta?.path ?? "/en/platform/about-us",
    meta: about_45usDKq87uBs94Meta || {},
    alias: about_45usDKq87uBs94Meta?.alias || [],
    redirect: about_45usDKq87uBs94Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usDKq87uBs94Meta?.name ?? "platform-about-us___tr",
    path: about_45usDKq87uBs94Meta?.path ?? "/tr/platform/about-us",
    meta: about_45usDKq87uBs94Meta || {},
    alias: about_45usDKq87uBs94Meta?.alias || [],
    redirect: about_45usDKq87uBs94Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usDKq87uBs94Meta?.name ?? "platform-about-us___ar",
    path: about_45usDKq87uBs94Meta?.path ?? "/platform/about-us",
    meta: about_45usDKq87uBs94Meta || {},
    alias: about_45usDKq87uBs94Meta?.alias || [],
    redirect: about_45usDKq87uBs94Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/about-us.vue").then(m => m.default || m)
  },
  {
    name: indexAcqTKb9K51Meta?.name ?? "platform-auth___en",
    path: indexAcqTKb9K51Meta?.path ?? "/en/platform/auth",
    children: [
  {
    name: email_45reset_45passwordg3hllAjW2LMeta?.name ?? "platform-auth-index-email-reset-password___en",
    path: email_45reset_45passwordg3hllAjW2LMeta?.path ?? "email-reset-password",
    meta: email_45reset_45passwordg3hllAjW2LMeta || {},
    alias: email_45reset_45passwordg3hllAjW2LMeta?.alias || [],
    redirect: email_45reset_45passwordg3hllAjW2LMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/email-reset-password.vue").then(m => m.default || m)
  },
  {
    name: forgotMdsdbfdPqVMeta?.name ?? "platform-auth-index-forgot___en",
    path: forgotMdsdbfdPqVMeta?.path ?? "forgot",
    meta: forgotMdsdbfdPqVMeta || {},
    alias: forgotMdsdbfdPqVMeta?.alias || [],
    redirect: forgotMdsdbfdPqVMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/forgot.vue").then(m => m.default || m)
  },
  {
    name: loginTR4JYpDLXFMeta?.name ?? "platform-auth-index-login___en",
    path: loginTR4JYpDLXFMeta?.path ?? "login",
    meta: loginTR4JYpDLXFMeta || {},
    alias: loginTR4JYpDLXFMeta?.alias || [],
    redirect: loginTR4JYpDLXFMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: phone_45reset_45password7mwDkxKcYLMeta?.name ?? "platform-auth-index-phone-reset-password___en",
    path: phone_45reset_45password7mwDkxKcYLMeta?.path ?? "phone-reset-password",
    meta: phone_45reset_45password7mwDkxKcYLMeta || {},
    alias: phone_45reset_45password7mwDkxKcYLMeta?.alias || [],
    redirect: phone_45reset_45password7mwDkxKcYLMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/phone-reset-password.vue").then(m => m.default || m)
  },
  {
    name: registerhOVDyGgfwiMeta?.name ?? "platform-auth-index-register___en",
    path: registerhOVDyGgfwiMeta?.path ?? "register",
    meta: registerhOVDyGgfwiMeta || {},
    alias: registerhOVDyGgfwiMeta?.alias || [],
    redirect: registerhOVDyGgfwiMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/register.vue").then(m => m.default || m)
  }
],
    meta: indexAcqTKb9K51Meta || {},
    alias: indexAcqTKb9K51Meta?.alias || [],
    redirect: indexAcqTKb9K51Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcqTKb9K51Meta?.name ?? "platform-auth___tr",
    path: indexAcqTKb9K51Meta?.path ?? "/tr/platform/auth",
    children: [
  {
    name: email_45reset_45passwordg3hllAjW2LMeta?.name ?? "platform-auth-index-email-reset-password___tr",
    path: email_45reset_45passwordg3hllAjW2LMeta?.path ?? "email-reset-password",
    meta: email_45reset_45passwordg3hllAjW2LMeta || {},
    alias: email_45reset_45passwordg3hllAjW2LMeta?.alias || [],
    redirect: email_45reset_45passwordg3hllAjW2LMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/email-reset-password.vue").then(m => m.default || m)
  },
  {
    name: forgotMdsdbfdPqVMeta?.name ?? "platform-auth-index-forgot___tr",
    path: forgotMdsdbfdPqVMeta?.path ?? "forgot",
    meta: forgotMdsdbfdPqVMeta || {},
    alias: forgotMdsdbfdPqVMeta?.alias || [],
    redirect: forgotMdsdbfdPqVMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/forgot.vue").then(m => m.default || m)
  },
  {
    name: loginTR4JYpDLXFMeta?.name ?? "platform-auth-index-login___tr",
    path: loginTR4JYpDLXFMeta?.path ?? "login",
    meta: loginTR4JYpDLXFMeta || {},
    alias: loginTR4JYpDLXFMeta?.alias || [],
    redirect: loginTR4JYpDLXFMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: phone_45reset_45password7mwDkxKcYLMeta?.name ?? "platform-auth-index-phone-reset-password___tr",
    path: phone_45reset_45password7mwDkxKcYLMeta?.path ?? "phone-reset-password",
    meta: phone_45reset_45password7mwDkxKcYLMeta || {},
    alias: phone_45reset_45password7mwDkxKcYLMeta?.alias || [],
    redirect: phone_45reset_45password7mwDkxKcYLMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/phone-reset-password.vue").then(m => m.default || m)
  },
  {
    name: registerhOVDyGgfwiMeta?.name ?? "platform-auth-index-register___tr",
    path: registerhOVDyGgfwiMeta?.path ?? "register",
    meta: registerhOVDyGgfwiMeta || {},
    alias: registerhOVDyGgfwiMeta?.alias || [],
    redirect: registerhOVDyGgfwiMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/register.vue").then(m => m.default || m)
  }
],
    meta: indexAcqTKb9K51Meta || {},
    alias: indexAcqTKb9K51Meta?.alias || [],
    redirect: indexAcqTKb9K51Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcqTKb9K51Meta?.name ?? "platform-auth___ar",
    path: indexAcqTKb9K51Meta?.path ?? "/platform/auth",
    children: [
  {
    name: email_45reset_45passwordg3hllAjW2LMeta?.name ?? "platform-auth-index-email-reset-password___ar",
    path: email_45reset_45passwordg3hllAjW2LMeta?.path ?? "email-reset-password",
    meta: email_45reset_45passwordg3hllAjW2LMeta || {},
    alias: email_45reset_45passwordg3hllAjW2LMeta?.alias || [],
    redirect: email_45reset_45passwordg3hllAjW2LMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/email-reset-password.vue").then(m => m.default || m)
  },
  {
    name: forgotMdsdbfdPqVMeta?.name ?? "platform-auth-index-forgot___ar",
    path: forgotMdsdbfdPqVMeta?.path ?? "forgot",
    meta: forgotMdsdbfdPqVMeta || {},
    alias: forgotMdsdbfdPqVMeta?.alias || [],
    redirect: forgotMdsdbfdPqVMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/forgot.vue").then(m => m.default || m)
  },
  {
    name: loginTR4JYpDLXFMeta?.name ?? "platform-auth-index-login___ar",
    path: loginTR4JYpDLXFMeta?.path ?? "login",
    meta: loginTR4JYpDLXFMeta || {},
    alias: loginTR4JYpDLXFMeta?.alias || [],
    redirect: loginTR4JYpDLXFMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: phone_45reset_45password7mwDkxKcYLMeta?.name ?? "platform-auth-index-phone-reset-password___ar",
    path: phone_45reset_45password7mwDkxKcYLMeta?.path ?? "phone-reset-password",
    meta: phone_45reset_45password7mwDkxKcYLMeta || {},
    alias: phone_45reset_45password7mwDkxKcYLMeta?.alias || [],
    redirect: phone_45reset_45password7mwDkxKcYLMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/phone-reset-password.vue").then(m => m.default || m)
  },
  {
    name: registerhOVDyGgfwiMeta?.name ?? "platform-auth-index-register___ar",
    path: registerhOVDyGgfwiMeta?.path ?? "register",
    meta: registerhOVDyGgfwiMeta || {},
    alias: registerhOVDyGgfwiMeta?.alias || [],
    redirect: registerhOVDyGgfwiMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index/register.vue").then(m => m.default || m)
  }
],
    meta: indexAcqTKb9K51Meta || {},
    alias: indexAcqTKb9K51Meta?.alias || [],
    redirect: indexAcqTKb9K51Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/index.vue").then(m => m.default || m)
  },
  {
    name: _providerpcLKZrrROBMeta?.name ?? "platform-auth-social-_provider___en",
    path: _providerpcLKZrrROBMeta?.path ?? "/en/platform/auth/social/_provider",
    meta: _providerpcLKZrrROBMeta || {},
    alias: _providerpcLKZrrROBMeta?.alias || [],
    redirect: _providerpcLKZrrROBMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/social/_provider.vue").then(m => m.default || m)
  },
  {
    name: _providerpcLKZrrROBMeta?.name ?? "platform-auth-social-_provider___tr",
    path: _providerpcLKZrrROBMeta?.path ?? "/tr/platform/auth/social/_provider",
    meta: _providerpcLKZrrROBMeta || {},
    alias: _providerpcLKZrrROBMeta?.alias || [],
    redirect: _providerpcLKZrrROBMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/social/_provider.vue").then(m => m.default || m)
  },
  {
    name: _providerpcLKZrrROBMeta?.name ?? "platform-auth-social-_provider___ar",
    path: _providerpcLKZrrROBMeta?.path ?? "/platform/auth/social/_provider",
    meta: _providerpcLKZrrROBMeta || {},
    alias: _providerpcLKZrrROBMeta?.alias || [],
    redirect: _providerpcLKZrrROBMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/social/_provider.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailezz4R1q6sTMeta?.name ?? "platform-auth-verify-email___en",
    path: verify_45emailezz4R1q6sTMeta?.path ?? "/en/platform/auth/verify-email",
    meta: verify_45emailezz4R1q6sTMeta || {},
    alias: verify_45emailezz4R1q6sTMeta?.alias || [],
    redirect: verify_45emailezz4R1q6sTMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailezz4R1q6sTMeta?.name ?? "platform-auth-verify-email___tr",
    path: verify_45emailezz4R1q6sTMeta?.path ?? "/tr/platform/auth/verify-email",
    meta: verify_45emailezz4R1q6sTMeta || {},
    alias: verify_45emailezz4R1q6sTMeta?.alias || [],
    redirect: verify_45emailezz4R1q6sTMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailezz4R1q6sTMeta?.name ?? "platform-auth-verify-email___ar",
    path: verify_45emailezz4R1q6sTMeta?.path ?? "/platform/auth/verify-email",
    meta: verify_45emailezz4R1q6sTMeta || {},
    alias: verify_45emailezz4R1q6sTMeta?.alias || [],
    redirect: verify_45emailezz4R1q6sTMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: indexwDwOpRfyS7Meta?.name ?? "platform___en",
    path: indexwDwOpRfyS7Meta?.path ?? "/en/platform",
    meta: indexwDwOpRfyS7Meta || {},
    alias: indexwDwOpRfyS7Meta?.alias || [],
    redirect: indexwDwOpRfyS7Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: indexwDwOpRfyS7Meta?.name ?? "platform___tr",
    path: indexwDwOpRfyS7Meta?.path ?? "/tr/platform",
    meta: indexwDwOpRfyS7Meta || {},
    alias: indexwDwOpRfyS7Meta?.alias || [],
    redirect: indexwDwOpRfyS7Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: indexwDwOpRfyS7Meta?.name ?? "platform___ar",
    path: indexwDwOpRfyS7Meta?.path ?? "/platform",
    meta: indexwDwOpRfyS7Meta || {},
    alias: indexwDwOpRfyS7Meta?.alias || [],
    redirect: indexwDwOpRfyS7Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cUecUnoOvLMeta?.name ?? "platform-materials-id___en",
    path: _91id_93cUecUnoOvLMeta?.path ?? "/en/platform/materials/:id()",
    meta: _91id_93cUecUnoOvLMeta || {},
    alias: _91id_93cUecUnoOvLMeta?.alias || [],
    redirect: _91id_93cUecUnoOvLMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93cUecUnoOvLMeta?.name ?? "platform-materials-id___tr",
    path: _91id_93cUecUnoOvLMeta?.path ?? "/tr/platform/materials/:id()",
    meta: _91id_93cUecUnoOvLMeta || {},
    alias: _91id_93cUecUnoOvLMeta?.alias || [],
    redirect: _91id_93cUecUnoOvLMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93cUecUnoOvLMeta?.name ?? "platform-materials-id___ar",
    path: _91id_93cUecUnoOvLMeta?.path ?? "/platform/materials/:id()",
    meta: _91id_93cUecUnoOvLMeta || {},
    alias: _91id_93cUecUnoOvLMeta?.alias || [],
    redirect: _91id_93cUecUnoOvLMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: index35pcWJHuM6Meta?.name ?? "platform-materials___en",
    path: index35pcWJHuM6Meta?.path ?? "/en/platform/materials",
    meta: index35pcWJHuM6Meta || {},
    alias: index35pcWJHuM6Meta?.alias || [],
    redirect: index35pcWJHuM6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index35pcWJHuM6Meta?.name ?? "platform-materials___tr",
    path: index35pcWJHuM6Meta?.path ?? "/tr/platform/materials",
    meta: index35pcWJHuM6Meta || {},
    alias: index35pcWJHuM6Meta?.alias || [],
    redirect: index35pcWJHuM6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index35pcWJHuM6Meta?.name ?? "platform-materials___ar",
    path: index35pcWJHuM6Meta?.path ?? "/platform/materials",
    meta: index35pcWJHuM6Meta || {},
    alias: index35pcWJHuM6Meta?.alias || [],
    redirect: index35pcWJHuM6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/materials/index.vue").then(m => m.default || m)
  },
  {
    path: indexx2oTV3vYZgMeta?.path ?? "/en/platform/my-account",
    children: [
  {
    name: edito5lPr0HeiIMeta?.name ?? "platform-my-account-index-edit___en",
    path: edito5lPr0HeiIMeta?.path ?? "edit",
    meta: edito5lPr0HeiIMeta || {},
    alias: edito5lPr0HeiIMeta?.alias || [],
    redirect: edito5lPr0HeiIMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKDQOPNGGcqMeta?.name ?? "platform-my-account-index___en",
    path: indexKDQOPNGGcqMeta?.path ?? "",
    meta: indexKDQOPNGGcqMeta || {},
    alias: indexKDQOPNGGcqMeta?.alias || [],
    redirect: indexKDQOPNGGcqMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsGcVJBER7TxMeta?.name ?? "platform-my-account-index-notifications___en",
    path: notificationsGcVJBER7TxMeta?.path ?? "notifications",
    meta: notificationsGcVJBER7TxMeta || {},
    alias: notificationsGcVJBER7TxMeta?.alias || [],
    redirect: notificationsGcVJBER7TxMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KJ1c1kgua2Meta?.name ?? "platform-my-account-index-orders-id___en",
    path: _91id_93KJ1c1kgua2Meta?.path ?? "orders/:id()",
    meta: _91id_93KJ1c1kgua2Meta || {},
    alias: _91id_93KJ1c1kgua2Meta?.alias || [],
    redirect: _91id_93KJ1c1kgua2Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyrG7oiRYwGMeta?.name ?? "platform-my-account-index-orders___en",
    path: indexyrG7oiRYwGMeta?.path ?? "orders",
    meta: indexyrG7oiRYwGMeta || {},
    alias: indexyrG7oiRYwGMeta?.alias || [],
    redirect: indexyrG7oiRYwGMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/orders/index.vue").then(m => m.default || m)
  },
  {
    name: placesqsCtzDv37KMeta?.name ?? "platform-my-account-index-places___en",
    path: placesqsCtzDv37KMeta?.path ?? "places",
    meta: placesqsCtzDv37KMeta || {},
    alias: placesqsCtzDv37KMeta?.alias || [],
    redirect: placesqsCtzDv37KMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/places.vue").then(m => m.default || m)
  },
  {
    name: wishlistkLai0HGqeeMeta?.name ?? "platform-my-account-index-wishlist___en",
    path: wishlistkLai0HGqeeMeta?.path ?? "wishlist",
    meta: wishlistkLai0HGqeeMeta || {},
    alias: wishlistkLai0HGqeeMeta?.alias || [],
    redirect: wishlistkLai0HGqeeMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/wishlist.vue").then(m => m.default || m)
  }
],
    name: indexx2oTV3vYZgMeta?.name ?? undefined,
    meta: indexx2oTV3vYZgMeta || {},
    alias: indexx2oTV3vYZgMeta?.alias || [],
    redirect: indexx2oTV3vYZgMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index.vue").then(m => m.default || m)
  },
  {
    path: indexx2oTV3vYZgMeta?.path ?? "/tr/platform/my-account",
    children: [
  {
    name: edito5lPr0HeiIMeta?.name ?? "platform-my-account-index-edit___tr",
    path: edito5lPr0HeiIMeta?.path ?? "edit",
    meta: edito5lPr0HeiIMeta || {},
    alias: edito5lPr0HeiIMeta?.alias || [],
    redirect: edito5lPr0HeiIMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKDQOPNGGcqMeta?.name ?? "platform-my-account-index___tr",
    path: indexKDQOPNGGcqMeta?.path ?? "",
    meta: indexKDQOPNGGcqMeta || {},
    alias: indexKDQOPNGGcqMeta?.alias || [],
    redirect: indexKDQOPNGGcqMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsGcVJBER7TxMeta?.name ?? "platform-my-account-index-notifications___tr",
    path: notificationsGcVJBER7TxMeta?.path ?? "notifications",
    meta: notificationsGcVJBER7TxMeta || {},
    alias: notificationsGcVJBER7TxMeta?.alias || [],
    redirect: notificationsGcVJBER7TxMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KJ1c1kgua2Meta?.name ?? "platform-my-account-index-orders-id___tr",
    path: _91id_93KJ1c1kgua2Meta?.path ?? "orders/:id()",
    meta: _91id_93KJ1c1kgua2Meta || {},
    alias: _91id_93KJ1c1kgua2Meta?.alias || [],
    redirect: _91id_93KJ1c1kgua2Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyrG7oiRYwGMeta?.name ?? "platform-my-account-index-orders___tr",
    path: indexyrG7oiRYwGMeta?.path ?? "orders",
    meta: indexyrG7oiRYwGMeta || {},
    alias: indexyrG7oiRYwGMeta?.alias || [],
    redirect: indexyrG7oiRYwGMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/orders/index.vue").then(m => m.default || m)
  },
  {
    name: placesqsCtzDv37KMeta?.name ?? "platform-my-account-index-places___tr",
    path: placesqsCtzDv37KMeta?.path ?? "places",
    meta: placesqsCtzDv37KMeta || {},
    alias: placesqsCtzDv37KMeta?.alias || [],
    redirect: placesqsCtzDv37KMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/places.vue").then(m => m.default || m)
  },
  {
    name: wishlistkLai0HGqeeMeta?.name ?? "platform-my-account-index-wishlist___tr",
    path: wishlistkLai0HGqeeMeta?.path ?? "wishlist",
    meta: wishlistkLai0HGqeeMeta || {},
    alias: wishlistkLai0HGqeeMeta?.alias || [],
    redirect: wishlistkLai0HGqeeMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/wishlist.vue").then(m => m.default || m)
  }
],
    name: indexx2oTV3vYZgMeta?.name ?? undefined,
    meta: indexx2oTV3vYZgMeta || {},
    alias: indexx2oTV3vYZgMeta?.alias || [],
    redirect: indexx2oTV3vYZgMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index.vue").then(m => m.default || m)
  },
  {
    path: indexx2oTV3vYZgMeta?.path ?? "/platform/my-account",
    children: [
  {
    name: edito5lPr0HeiIMeta?.name ?? "platform-my-account-index-edit___ar",
    path: edito5lPr0HeiIMeta?.path ?? "edit",
    meta: edito5lPr0HeiIMeta || {},
    alias: edito5lPr0HeiIMeta?.alias || [],
    redirect: edito5lPr0HeiIMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKDQOPNGGcqMeta?.name ?? "platform-my-account-index___ar",
    path: indexKDQOPNGGcqMeta?.path ?? "",
    meta: indexKDQOPNGGcqMeta || {},
    alias: indexKDQOPNGGcqMeta?.alias || [],
    redirect: indexKDQOPNGGcqMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsGcVJBER7TxMeta?.name ?? "platform-my-account-index-notifications___ar",
    path: notificationsGcVJBER7TxMeta?.path ?? "notifications",
    meta: notificationsGcVJBER7TxMeta || {},
    alias: notificationsGcVJBER7TxMeta?.alias || [],
    redirect: notificationsGcVJBER7TxMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KJ1c1kgua2Meta?.name ?? "platform-my-account-index-orders-id___ar",
    path: _91id_93KJ1c1kgua2Meta?.path ?? "orders/:id()",
    meta: _91id_93KJ1c1kgua2Meta || {},
    alias: _91id_93KJ1c1kgua2Meta?.alias || [],
    redirect: _91id_93KJ1c1kgua2Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyrG7oiRYwGMeta?.name ?? "platform-my-account-index-orders___ar",
    path: indexyrG7oiRYwGMeta?.path ?? "orders",
    meta: indexyrG7oiRYwGMeta || {},
    alias: indexyrG7oiRYwGMeta?.alias || [],
    redirect: indexyrG7oiRYwGMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/orders/index.vue").then(m => m.default || m)
  },
  {
    name: placesqsCtzDv37KMeta?.name ?? "platform-my-account-index-places___ar",
    path: placesqsCtzDv37KMeta?.path ?? "places",
    meta: placesqsCtzDv37KMeta || {},
    alias: placesqsCtzDv37KMeta?.alias || [],
    redirect: placesqsCtzDv37KMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/places.vue").then(m => m.default || m)
  },
  {
    name: wishlistkLai0HGqeeMeta?.name ?? "platform-my-account-index-wishlist___ar",
    path: wishlistkLai0HGqeeMeta?.path ?? "wishlist",
    meta: wishlistkLai0HGqeeMeta || {},
    alias: wishlistkLai0HGqeeMeta?.alias || [],
    redirect: wishlistkLai0HGqeeMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index/wishlist.vue").then(m => m.default || m)
  }
],
    name: indexx2oTV3vYZgMeta?.name ?? undefined,
    meta: indexx2oTV3vYZgMeta || {},
    alias: indexx2oTV3vYZgMeta?.alias || [],
    redirect: indexx2oTV3vYZgMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: offersN0Z0vEMvauMeta?.name ?? "platform-offers___en",
    path: offersN0Z0vEMvauMeta?.path ?? "/en/platform/offers",
    meta: offersN0Z0vEMvauMeta || {},
    alias: offersN0Z0vEMvauMeta?.alias || [],
    redirect: offersN0Z0vEMvauMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/offers.vue").then(m => m.default || m)
  },
  {
    name: offersN0Z0vEMvauMeta?.name ?? "platform-offers___tr",
    path: offersN0Z0vEMvauMeta?.path ?? "/tr/platform/offers",
    meta: offersN0Z0vEMvauMeta || {},
    alias: offersN0Z0vEMvauMeta?.alias || [],
    redirect: offersN0Z0vEMvauMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/offers.vue").then(m => m.default || m)
  },
  {
    name: offersN0Z0vEMvauMeta?.name ?? "platform-offers___ar",
    path: offersN0Z0vEMvauMeta?.path ?? "/platform/offers",
    meta: offersN0Z0vEMvauMeta || {},
    alias: offersN0Z0vEMvauMeta?.alias || [],
    redirect: offersN0Z0vEMvauMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/offers.vue").then(m => m.default || m)
  },
  {
    name: privacyTunBmyshG6Meta?.name ?? "platform-privacy___en",
    path: privacyTunBmyshG6Meta?.path ?? "/en/platform/privacy",
    meta: privacyTunBmyshG6Meta || {},
    alias: privacyTunBmyshG6Meta?.alias || [],
    redirect: privacyTunBmyshG6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTunBmyshG6Meta?.name ?? "platform-privacy___tr",
    path: privacyTunBmyshG6Meta?.path ?? "/tr/platform/privacy",
    meta: privacyTunBmyshG6Meta || {},
    alias: privacyTunBmyshG6Meta?.alias || [],
    redirect: privacyTunBmyshG6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTunBmyshG6Meta?.name ?? "platform-privacy___ar",
    path: privacyTunBmyshG6Meta?.path ?? "/platform/privacy",
    meta: privacyTunBmyshG6Meta || {},
    alias: privacyTunBmyshG6Meta?.alias || [],
    redirect: privacyTunBmyshG6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/privacy.vue").then(m => m.default || m)
  },
  {
    name: store_45typesER6Ann1IOoMeta?.name ?? "platform-store-types___en",
    path: store_45typesER6Ann1IOoMeta?.path ?? "/en/platform/store-types",
    meta: store_45typesER6Ann1IOoMeta || {},
    alias: store_45typesER6Ann1IOoMeta?.alias || [],
    redirect: store_45typesER6Ann1IOoMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/store-types.vue").then(m => m.default || m)
  },
  {
    name: store_45typesER6Ann1IOoMeta?.name ?? "platform-store-types___tr",
    path: store_45typesER6Ann1IOoMeta?.path ?? "/tr/platform/store-types",
    meta: store_45typesER6Ann1IOoMeta || {},
    alias: store_45typesER6Ann1IOoMeta?.alias || [],
    redirect: store_45typesER6Ann1IOoMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/store-types.vue").then(m => m.default || m)
  },
  {
    name: store_45typesER6Ann1IOoMeta?.name ?? "platform-store-types___ar",
    path: store_45typesER6Ann1IOoMeta?.path ?? "/platform/store-types",
    meta: store_45typesER6Ann1IOoMeta || {},
    alias: store_45typesER6Ann1IOoMeta?.alias || [],
    redirect: store_45typesER6Ann1IOoMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/store-types.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RUoi13JnnlMeta?.name ?? "platform-stores-id___en",
    path: _91id_93RUoi13JnnlMeta?.path ?? "/en/platform/stores/:id()",
    meta: _91id_93RUoi13JnnlMeta || {},
    alias: _91id_93RUoi13JnnlMeta?.alias || [],
    redirect: _91id_93RUoi13JnnlMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93RUoi13JnnlMeta?.name ?? "platform-stores-id___tr",
    path: _91id_93RUoi13JnnlMeta?.path ?? "/tr/platform/stores/:id()",
    meta: _91id_93RUoi13JnnlMeta || {},
    alias: _91id_93RUoi13JnnlMeta?.alias || [],
    redirect: _91id_93RUoi13JnnlMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93RUoi13JnnlMeta?.name ?? "platform-stores-id___ar",
    path: _91id_93RUoi13JnnlMeta?.path ?? "/platform/stores/:id()",
    meta: _91id_93RUoi13JnnlMeta || {},
    alias: _91id_93RUoi13JnnlMeta?.alias || [],
    redirect: _91id_93RUoi13JnnlMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: indexV2SKA9vem6Meta?.name ?? "platform-stores___en",
    path: indexV2SKA9vem6Meta?.path ?? "/en/platform/stores",
    meta: indexV2SKA9vem6Meta || {},
    alias: indexV2SKA9vem6Meta?.alias || [],
    redirect: indexV2SKA9vem6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/stores/index.vue").then(m => m.default || m)
  },
  {
    name: indexV2SKA9vem6Meta?.name ?? "platform-stores___tr",
    path: indexV2SKA9vem6Meta?.path ?? "/tr/platform/stores",
    meta: indexV2SKA9vem6Meta || {},
    alias: indexV2SKA9vem6Meta?.alias || [],
    redirect: indexV2SKA9vem6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/stores/index.vue").then(m => m.default || m)
  },
  {
    name: indexV2SKA9vem6Meta?.name ?? "platform-stores___ar",
    path: indexV2SKA9vem6Meta?.path ?? "/platform/stores",
    meta: indexV2SKA9vem6Meta || {},
    alias: indexV2SKA9vem6Meta?.alias || [],
    redirect: indexV2SKA9vem6Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/stores/index.vue").then(m => m.default || m)
  },
  {
    name: successful_45purchase_45cartnC9GDSCanRMeta?.name ?? "platform-successful-purchase-cart___en",
    path: successful_45purchase_45cartnC9GDSCanRMeta?.path ?? "/en/platform/successful-purchase-cart",
    meta: successful_45purchase_45cartnC9GDSCanRMeta || {},
    alias: successful_45purchase_45cartnC9GDSCanRMeta?.alias || [],
    redirect: successful_45purchase_45cartnC9GDSCanRMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/successful-purchase-cart.vue").then(m => m.default || m)
  },
  {
    name: successful_45purchase_45cartnC9GDSCanRMeta?.name ?? "platform-successful-purchase-cart___tr",
    path: successful_45purchase_45cartnC9GDSCanRMeta?.path ?? "/tr/platform/successful-purchase-cart",
    meta: successful_45purchase_45cartnC9GDSCanRMeta || {},
    alias: successful_45purchase_45cartnC9GDSCanRMeta?.alias || [],
    redirect: successful_45purchase_45cartnC9GDSCanRMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/successful-purchase-cart.vue").then(m => m.default || m)
  },
  {
    name: successful_45purchase_45cartnC9GDSCanRMeta?.name ?? "platform-successful-purchase-cart___ar",
    path: successful_45purchase_45cartnC9GDSCanRMeta?.path ?? "/platform/successful-purchase-cart",
    meta: successful_45purchase_45cartnC9GDSCanRMeta || {},
    alias: successful_45purchase_45cartnC9GDSCanRMeta?.alias || [],
    redirect: successful_45purchase_45cartnC9GDSCanRMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/successful-purchase-cart.vue").then(m => m.default || m)
  },
  {
    name: termsAJpDsJVmA5Meta?.name ?? "platform-terms___en",
    path: termsAJpDsJVmA5Meta?.path ?? "/en/platform/terms",
    meta: termsAJpDsJVmA5Meta || {},
    alias: termsAJpDsJVmA5Meta?.alias || [],
    redirect: termsAJpDsJVmA5Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/terms.vue").then(m => m.default || m)
  },
  {
    name: termsAJpDsJVmA5Meta?.name ?? "platform-terms___tr",
    path: termsAJpDsJVmA5Meta?.path ?? "/tr/platform/terms",
    meta: termsAJpDsJVmA5Meta || {},
    alias: termsAJpDsJVmA5Meta?.alias || [],
    redirect: termsAJpDsJVmA5Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/terms.vue").then(m => m.default || m)
  },
  {
    name: termsAJpDsJVmA5Meta?.name ?? "platform-terms___ar",
    path: termsAJpDsJVmA5Meta?.path ?? "/platform/terms",
    meta: termsAJpDsJVmA5Meta || {},
    alias: termsAJpDsJVmA5Meta?.alias || [],
    redirect: termsAJpDsJVmA5Meta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/platform/terms.vue").then(m => m.default || m)
  },
  {
    name: cartmVBZuYxTtYMeta?.name ?? "shared-cart___en",
    path: cartmVBZuYxTtYMeta?.path ?? "/en/shared/cart",
    meta: cartmVBZuYxTtYMeta || {},
    alias: cartmVBZuYxTtYMeta?.alias || [],
    redirect: cartmVBZuYxTtYMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/shared/cart.vue").then(m => m.default || m)
  },
  {
    name: cartmVBZuYxTtYMeta?.name ?? "shared-cart___tr",
    path: cartmVBZuYxTtYMeta?.path ?? "/tr/shared/cart",
    meta: cartmVBZuYxTtYMeta || {},
    alias: cartmVBZuYxTtYMeta?.alias || [],
    redirect: cartmVBZuYxTtYMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/shared/cart.vue").then(m => m.default || m)
  },
  {
    name: cartmVBZuYxTtYMeta?.name ?? "shared-cart___ar",
    path: cartmVBZuYxTtYMeta?.path ?? "/shared/cart",
    meta: cartmVBZuYxTtYMeta || {},
    alias: cartmVBZuYxTtYMeta?.alias || [],
    redirect: cartmVBZuYxTtYMeta?.redirect || undefined,
    component: () => import("/home/e-store/node_menu/pages/shared/cart.vue").then(m => m.default || m)
  }
]