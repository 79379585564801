import { getRequestHost, getRequestHeader } from 'h3'
import type { Store } from '~~/composables/useMenuModel'
export const useDomainHost = () => {
  let host = ''
  let layout: 'platform' | 'domain' | 'default' = 'default'

  const nuxtApp = useNuxtApp()
  const platform = usePlatform()

  let domains: string[] = []

  if (process.server) {
    domains = JSON.parse(process.env.NUXT_PUBLIC_DOMAINS || '[]')
  } else {
    domains = useState<string[]>('valid_domains').value
  }

  if (process.env.NODE_ENV === 'development') {
    host = useDomain()
  } else if (process.server && nuxtApp.ssrContext) {
    host =
      getRequestHeader(nuxtApp.ssrContext.event, 'x-forwarded-host') ||
      getRequestHost(nuxtApp.ssrContext.event)
  } else if (process.client && window) {
    host = window.location.host
  }

  while (typeof domains === 'string') {
    domains = JSON.parse(domains)
  }

  if (host.replace('www.', '') === platform.replace('www.', '')) {
    layout = 'platform'
  } else if (
    domains?.find((el) => el.replace('www.', '') === host.replace('www.', ''))
  ) {
    layout = 'domain'
  }

  return {
    url: 'https://' + host.replace('www.', ''),
    host: host.replace('www.', ''),
    layout
  }
}
export const useIsDomain = () => {
  const { layout } = useDomainHost()
  return layout === 'domain'
}
export const useCurrentBranch = (
  branchId?: number,
  branches?: BranchStore[]
) => {
  const { store: storeDomain } = useDomainState()
  const branchesTemp = useIsDomain() ? storeDomain.value?.branches : branches
  return branchesTemp?.find(
    (el) => el.id.toString() === useBranchId(branchId)?.toString()
  )
}
export const useBranchId = (branchId?: number) => {
  return branchId || parseInt(useRoute().query.branch_id as string) || undefined
}

export const useWhatsappNumer = (
  isLoungePhone?: boolean,
  store?: Store,
  branchStore?: BranchStore
) => {
  let phone = store?.whats_app_number || store?.lounge_phone
  if (isLoungePhone && store?.lounge_phone) {
    phone = store!.lounge_phone
  }
  phone = branchStore?.phone || phone
  return phone
}
export const useDomainState = () => {
  const store = useState<Store | null>('domain_store', () => null)

  const setStore = (data: Store | null) => (store.value = data)

  function storeRoute(id?: number | String) {
    if (!id || store.value !== null) {
      return { path: '/' }
    }

    return { name: 'stores-id', params: { id } }
  }

  return {
    store,
    setStore,
    storeRoute
  }
}
