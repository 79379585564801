import type { City } from '~/composables/useMenuModel'

export const useSettings = () => {
  const settings = useState<GlobalSettings>('settings', () => ({}))

  const setSettings = (data: GlobalSettings) => (settings.value = data)

  return {
    settings,
    setSettings
  }
}

export const useGroupSettings = async (group: number, key: string) => {
  const { data } = await useNuxtApiFetch<ApiResponse<any>>(
    '/laravel/settings/group',
    { query: { group, domain: useDomainHeader() } }
  )
  if (data.value) {
    return data.value[key] as any
  }
}
export const useStoresSettings = () => {
  const settingsStore = useState<StoresSetting[]>('StoresSetting', () => [])
  function getSittingsByKey(key: String) {
    const sittingTemp = settingsStore.value.find((el) => el.key === key)
    return sittingTemp?.pivot?.value.trim()
  }
  async function fetchSettingsStore(store: Store) {
    const { data } = await useNuxtApiFetch<StoresSetting[]>(
      '/laravel/settings/storesSettings',
      {
        query: {
          storeId: store?.id,
          domain: useDomainHeader(),
          group: 17
        }
      }
    )
    if (data.value?.length) {
      data.value?.forEach((el) => {
        if (el?.pivot?.value.trim() && !settingsStore.value?.includes(el)) {
          settingsStore.value.push(el)
        }
      })
    }
  }
  return {
    settingsStore,
    fetchSettingsStore,
    getSittingsByKey
  }
}
export const useAgentPlaces = () => {
  const { status } = useAuth()
  const AgentPlacesValue = useState<{
    places: AgentPlace[]
    isFetched: boolean
  }>(
    'AgentPlaces',
    () =>
      <{ places: AgentPlace[]; isFetched: boolean }>{
        isFetched: false,
        places: []
      }
  )
  const getPlaces = computed(() => {
    return AgentPlacesValue.value.places
  })
  async function fetchAgentPlace() {
    if (status.value === 'authenticated') {
      if (!AgentPlacesValue.value.isFetched) {
        const { data, error } = await useFetch('/nuxt-api/user/agentPlace', {
          transform: (data: SuccessResponse<AgentPlace[]>) => {
            return data.data
          }
        })
        if (!error.value) {
          AgentPlacesValue.value.isFetched = true
        }
        AgentPlacesValue.value.places = data.value ?? []
      }
    }
    return AgentPlacesValue.value.places
  }
  return {
    AgentPlacesValue,
    getPlaces,
    fetchAgentPlace
  }
}
export const useCities = () => {
  const cities = useState<City[]>('cities', () => [])

  const setCities = (data: City[]) => (cities.value = data)

  return {
    cities,
    setCities
  }
}

export const useStoreTypes = () => {
  const storeTypes = useState<StoreType[]>('storeTypes', () => [])
  const storeTypesParents = computed(() =>
    storeTypes.value?.filter((el) => el.parent_id == null)
  )
  const setStoreTypes = (data: StoreType[]) => (storeTypes.value = data)

  return {
    storeTypes,
    setStoreTypes,
    storeTypesParents
  }
}

export const useMaterialDefinitionsStore = () => {
  const materialDefinitions = useState<MaterialDefinition[]>(
    'MaterialDefinitionsStore',
    () => []
  )
  async function fetchMaterialDefinitionsStore(store: Store) {
    const { data } = await useApiFetch<SuccessResponse<MaterialDefinition[]>>(
      '/material-definitions',
      {
        query: {
          type_id: store?.type_id,
          limit: 1000,
          domain: useDomainHeader()
        }
      }
    )

    if (data.value?.data?.length) {
      const { materialDfinitionsModel } = useMenuModel()
      materialDefinitions.value = materialDfinitionsModel(data.value?.data) ?? []
    }
  }
  return {
    materialDefinitions,
    fetchMaterialDefinitionsStore
  }
}
