export const useBaseUrl = (url: string) => {
  if (process.server) {
    return process.env.NUXT_PUBLIC_BASE_URL?.replaceAll('"', '') + url
  }

  return useRuntimeConfig().public.baseUrl + url
}

export const useCurrency = () => {
  if (process.server) {
    return process.env.NUXT_PUBLIC_CURRENCY?.replaceAll('"', '')
  }

  return useRuntimeConfig().public.currency
}

export const useStorageUrl = (url: string) => {
  if (process.server) {
    return process.env.NUXT_PUBLIC_STORAGE_URL?.replaceAll('"', '') + url
  }

  return useRuntimeConfig().public.storageUrl + url
}

export const useDomain = () => {
  if (process.server) {
    return (
      process.env.NUXT_PUBLIC_DOMAIN?.replaceAll('"', '') || 'localhost:3000'
    )
  }

  return useRuntimeConfig().public.domain
}

export const useMapKey = () => {
  if (process.server) {
    return process.env.NUXT_PUBLIC_MAP_KEY?.replaceAll('"', '')
  }

  return useRuntimeConfig().public.mapKey
}
export const usePlatform = () => {
  if (process.server) {
    return (
      process.env.NUXT_PUBLIC_PLATFORM?.replaceAll('"', '') || 'localhost:3000'
    )
  }

  return useRuntimeConfig().public.platform
}

export const useAppUrl = () => {
  if (process.server) {
    return process.env.NUXT_PUBLIC_APP_URL?.replaceAll('"', '')
  }

  return useRuntimeConfig().public.appUrl
}
