
// @ts-nocheck
import locale__home_e_store_node_menu_i18n_en_json from "../i18n/en.json";
import locale__home_e_store_node_menu_i18n_tr_json from "../i18n/tr.json";
import locale__home_e_store_node_menu_i18n_ar_json from "../i18n/ar.json";


export const localeCodes =  [
  "en",
  "tr",
  "ar"
]

export const localeMessages = {
  "en": [{ key: "../i18n/en.json", load: () => Promise.resolve(locale__home_e_store_node_menu_i18n_en_json), cache: true }],
  "tr": [{ key: "../i18n/tr.json", load: () => Promise.resolve(locale__home_e_store_node_menu_i18n_tr_json), cache: true }],
  "ar": [{ key: "../i18n/ar.json", load: () => Promise.resolve(locale__home_e_store_node_menu_i18n_ar_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en-US",
      "dir": "ltr",
      "files": [
        "i18n/en.json"
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "iso": "tr-TR",
      "dir": "ltr",
      "files": [
        "i18n/tr.json"
      ]
    },
    {
      "code": "ar",
      "name": "العربية",
      "iso": "ar-EG",
      "dir": "rtl",
      "files": [
        "i18n/ar.json"
      ]
    }
  ],
  "defaultLocale": "ar",
  "defaultDirection": "rtl",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./i18n",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "http://localhost:3000",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en-US",
      "dir": "ltr",
      "files": [
        {
          "path": "i18n/en.json"
        }
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "iso": "tr-TR",
      "dir": "ltr",
      "files": [
        {
          "path": "i18n/tr.json"
        }
      ]
    },
    {
      "code": "ar",
      "name": "العربية",
      "iso": "ar-EG",
      "dir": "rtl",
      "files": [
        {
          "path": "i18n/ar.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

