import { defineStore } from 'pinia'

export const useCityIdStore = defineStore('city_id_filter', {
  state: (): { city_id: string | undefined } => ({ city_id: undefined }),

  getters: {
    cityId: (state) => state.city_id
  },

  actions: {
    setCityId(id?: string) {
      this.city_id = id
    }
  },

  persist: [{ paths: ['city_id'], storage: persistedState.cookies }]
})
