import revive_payload_client_4sVQNw7RlN from "/home/e-store/node_menu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/e-store/node_menu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/e-store/node_menu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/e-store/node_menu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/e-store/node_menu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/e-store/node_menu/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/e-store/node_menu/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/e-store/node_menu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/e-store/node_menu/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/home/e-store/node_menu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/e-store/node_menu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import templates_pwa_client_6cd10022_usSNQ0g3ta from "/home/e-store/node_menu/.nuxt/templates.pwa.client.6cd10022.ts";
import plugin_t2GMTTFnMT from "/home/e-store/node_menu/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/home/e-store/node_menu/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/e-store/node_menu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/e-store/node_menu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _01_resize_observer_client_bORNNEGTws from "/home/e-store/node_menu/plugins/01.resize_observer.client.ts";
import _02_vuetify_rwZ6VQqJQJ from "/home/e-store/node_menu/plugins/02.vuetify.ts";
import _03_toastification_TRcESN6V9o from "/home/e-store/node_menu/plugins/03.toastification.ts";
import _04_publicEvent_GPsUUiLu3C from "/home/e-store/node_menu/plugins/04.publicEvent.ts";
import _05_sentry_client_aLToQO08jh from "/home/e-store/node_menu/plugins/05.sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  templates_pwa_client_6cd10022_usSNQ0g3ta,
  plugin_t2GMTTFnMT,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  _01_resize_observer_client_bORNNEGTws,
  _02_vuetify_rwZ6VQqJQJ,
  _03_toastification_TRcESN6V9o,
  _04_publicEvent_GPsUUiLu3C,
  _05_sentry_client_aLToQO08jh
]